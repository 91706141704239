
import { defineComponent } from "vue";
import IPokemon from "@/interfaces/IPokemon";
import type { PropType } from "vue";

export default defineComponent({
  name: "BattleDescriptionComponent",
  props: {
    firstPokemon: {
      type: Object as PropType<IPokemon>,
      required: true,
    },
    secondPokemon: {
      type: Object as PropType<IPokemon>,
      required: true,
    },
  },
});
