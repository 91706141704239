import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33d6cda2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", {
    class: _normalizeClass(["pokemon-card", { selected: _ctx.pokemon.is_selected }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick', _ctx.pokemon)))
  }, [
    _createElementVNode("figure", null, [
      _createElementVNode("figcaption", null, _toDisplayString(_ctx.pokemon.name), 1),
      _createElementVNode("img", {
        src: _ctx.pokemon.sprites.front_default,
        alt: _ctx.pokemon.name
      }, null, 8, _hoisted_1)
    ])
  ], 2))
}