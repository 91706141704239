
import { defineComponent } from "vue";

let timeout: number | undefined;
const defaultTimeoutInMS = 3000;

export default defineComponent({
  name: "SnackbarComponent",
  mounted() {
    timeout = setTimeout(() => {
      this.emitOnCloseEvent();
    }, defaultTimeoutInMS);
  },
  beforeUnmount() {
    clearTimeout(timeout);
  },
  methods: {
    emitOnCloseEvent() {
      this.$emit("onClose");
    },
  },
});
