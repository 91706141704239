
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import IPokemon from "@/interfaces/IPokemon";
import type { PropType } from "vue";

export default defineComponent({
  name: "EndGameComponent",
  components: {
    Button,
  },
  props: {
    champion: {
      type: Object as PropType<IPokemon>,
      required: true,
    },
  },
  methods: {
    handleOnClick() {
      this.$emit("playAgain");
    },
  },
});
