
import { defineComponent } from "vue";
import { getPokemons } from "@/services/pokemon";
import Battle from "@/components/Battle.vue";
import Card from "@/components/Card.vue";
import EndGame from "@/components/EndGame.vue";
import IHomeViewData from "@/interfaces/IHomeViewData";
import IPokemon from "@/interfaces/IPokemon";
import Snackbar from "@/components/Snackbar.vue";
import { getRandomNumberBetween } from "@/helpers";
import { POKEMONS_COUNT, POKEMONS_PER_PAGE } from "@/constants";

export default defineComponent({
  name: "HomeView",
  components: {
    Battle,
    Card,
    EndGame,
    Snackbar,
  },
  data(): IHomeViewData {
    return {
      loading: false,
      pokemons: [] as IPokemon[],
      firstSelectedPokemon: null,
      secondSelectedPokemon: null,
      winner: null,
    };
  },
  computed: {
    champion(): IPokemon | null {
      if (this.pokemons.length !== 1) return null;

      return this.winner || this.champion;
    },
  },
  mounted() {
    this.listPokemons();
  },
  methods: {
    async listPokemons() {
      this.loading = true;
      const queryData = this.getQueryData();
      this.pokemons = await getPokemons(queryData);
      this.loading = false;
    },
    getQueryData() {
      return {
        limit: POKEMONS_PER_PAGE,
        offset: getRandomNumberBetween(0, POKEMONS_COUNT - POKEMONS_PER_PAGE),
      };
    },
    handleOnClickPokemon(selectedPokemon: IPokemon) {
      this.handleOnCloseSnackbar();

      if (this.firstSelectedPokemon?.name === selectedPokemon.name) {
        this.firstSelectedPokemon = null;
        this.updatePokemons(selectedPokemon);
        return;
      }
      if (this.secondSelectedPokemon?.name === selectedPokemon.name) {
        this.secondSelectedPokemon = null;
        this.updatePokemons(selectedPokemon);
        return;
      }
      if (this.firstSelectedPokemon === null) {
        this.firstSelectedPokemon = selectedPokemon;
        this.updatePokemons(selectedPokemon);
        return;
      }
      if (this.secondSelectedPokemon === null) {
        this.secondSelectedPokemon = selectedPokemon;
        this.updatePokemons(selectedPokemon);
        return;
      }
    },
    handleOnPlayAgain() {
      this.firstSelectedPokemon = null;
      this.secondSelectedPokemon = null;
      this.winner = null;
      this.listPokemons();
    },
    updatePokemons(selectedPokemon: IPokemon) {
      this.pokemons = this.pokemons.map((pokemon) => {
        if (selectedPokemon.name === pokemon.name) {
          return {
            ...pokemon,
            is_selected: !pokemon.is_selected,
          };
        }

        return pokemon;
      });
    },
    handleOnFightEnd(loser: IPokemon) {
      this.pokemons = this.pokemons.filter(
        (pokemon) => pokemon.name !== loser.name
      );
      if (this.firstSelectedPokemon?.name === loser.name) {
        this.firstSelectedPokemon = null;
      }
      if (this.secondSelectedPokemon?.name === loser.name) {
        this.secondSelectedPokemon = null;
      }
      this.winner = this.getWinner();
    },
    getWinner() {
      return this.firstSelectedPokemon || this.secondSelectedPokemon;
    },
    handleOnCloseSnackbar() {
      this.winner = null;
    },
  },
});
